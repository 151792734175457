const EmployeeService = require("@/services/users/EmployeeService");
 
export default function(cb, preset) {
    console.log("EmployeesFilter function called"); // Add this to verify
 
    let value = null;
 
    const data = {
        includes: ["department"],
        sort: [
            {
                key: "email",
                direction: "ASC"
            }
        ],
        filter_groups: [
            {
                filters: [
                    {
                        key: "terminated",
                        value: 0,
                        operator: "eq"
                    }
                ]
            }
        ]
    };
 
    EmployeeService.findAll(data, response => {
        const body = response.data;
 
        if (!body.rows || !Array.isArray(body.rows)) {
            cb([], null); // Fix here
            return;
        }
 
        const results = body.rows.map(employee => ({
            label: `${employee.email} - ${employee.name}`,
            value: employee.id
        }));
 
        cb(results, value);
    });
}