<template>
	<card icon="map-marker"
		  :headline="$t('serpGrid.headline')"
		  :description="$t('serpGrid.description')">
		<div class="local-serp-grid">
			<div class="results">
				<div class="profiles">
					<div class="top">
						<h2>{{ $t('serpGrid.googleBusinessProfiles') }}</h2>

						<div class="search">
							<i class="far fa-search fa-fw" />

							<input type="text"
								   v-model="query"
								   :placeholder="$t('filters.search')"
							/>

							<button v-if="String(query || '').length > 0"
									type="button"
									@click="query = ''">
								<i class="far fa-times fa-fw" />
							</button>
						</div>
					</div>

					<div v-if="Object.values(profiles).length > 0"
						 class="results">
						<div v-for="profile in sortedProfiles"
							 :key="`profile-${profile.id}`"
							 @click="id = profile.id"
							 :class="{ active: id === profile.id }"
							 class="result">
							<div class="meta">
								<div class="name">{{ profile.title }}</div>
								<div v-if="!! profile.address" class="address">{{ profile.address }}</div>
								<div v-if="!! profile.rating" class="rating">
									<span class="rating">{{ profile.rating.value }}</span>
									<i v-for="n in stars(profile.rating.value).full" class="fas fa-star fa-fw star full" />
									<i v-if="stars(profile.rating.value).half" class="fa-duotone fa-star-half star" />
									<i v-for="n in stars(profile.rating.value).empty" class="fas fa-star fa-fw star" />
									<span class="count">({{ profile.rating.count }})</span>
								</div>
							</div>

							<div class="position">
								<div class="value"
									 v-text="parseFloat(profile.average_rank).toFixed(1)"
									 :class="{
										good: parseFloat(profile.average_rank).toFixed(1) < 4,
										medium: parseFloat(profile.average_rank).toFixed(1) >= 4 && parseFloat(profile.average_rank).toFixed(1) < 8,
										bad: parseFloat(profile.average_rank).toFixed(1) >= 4 && parseFloat(profile.average_rank).toFixed(1) >=8,
									 }"
								/>
								<div class="tip">
									<template v-if="parseFloat(profile.average_rank).toFixed(1) < 4">This is an excellent placement, indicating that the Google Business Profile is well-optimized and visible to potential customers. The profile is among the top, but it could be considered to work more locally in other areas.</template>
									<template v-else-if="parseFloat(profile.average_rank).toFixed(1) < 8">This indicates that the Google Business Profile has a good starting point, but there is room for improvements to increase visibility. The profile receives most views and clicks with a ranking of 1-3.</template>
									<template v-else>This indicates a lack of optimization on the Google Business Profile. To improve visibility and attract more customers, a special effort is needed.</template>
								</div>
							</div>
						</div>
					</div>

					<div v-else-if="loading"
						 class="loader">
						<i class="fas fa-spinner-third fa-spin fa-fw" />
						<span>{{ $t('serpGrid.searching') }}</span>
					</div>

					<div v-else
						 class="placeholder">
						{{ $t('serpGrid.searchPlaceholder') }}
					</div>
				</div>

				<div class="map-wrapper">
					<div class="search">
						<div class="field">
							<input ref="address"
								   type="text"
								   v-model="address"
								   :placeholder="$t('serpGrid.form.address')"
								   :disabled="loading"
							/>

							<i v-if="String(address || '').length > 0"
							   @click="address = null"
							   class="fas fa-times fa-fw"
							/>
						</div>

						<div class="field last">
							<input v-model="keyword"
								   type="text"
								   :placeholder="$t('serpGrid.form.keyword')"
								   :disabled="loading"
							/>
						</div>

						<div v-if="1 === 3" class="field last">
							<div class="switch">
								<button type="button"
										@click="setType('local')"
										:class="{ active: type === 'local' }">
									<i class="far fa-map-marker fa-fw" />
									<span>{{ $t('serpGrid.form.local') }}</span>
								</button>

								<button type="button"
										@click="setType('map')"
										:class="{ active: type === 'map' }">
									<i class="far fa-map fa-fw" />
									<span>{{ $t('serpGrid.form.map') }}</span>
								</button>
							</div>
						</div>

						<button @click="onSearch"
								:disabled="! canSubmit || loading"
								type="button">
							<i class="fas fa-search fa-fw" />
							<span>{{ $t('serpGrid.form.search') }}</span>
						</button>
					</div>

					<div v-if="loading"
						 class="loader">
						<div class="spinner">
							<i class="fas fa-spinner-third fa-spin fa-fw" />
							<span>{{ progress.pending }} / {{ progress.total }}</span>
						</div>

						<serp-tips />
					</div>

					<div ref="map"
						 class="serp-map"
					/>
				</div>
			</div>
		</div>
	</card>
</template>

<style lang="scss" scoped>
.local-serp-grid {
	> div.results {
		display: flex;
		min-height: 700px;
		height: 700px;
		border-radius: 6px;
		overflow: hidden;
		border: 1px solid #dadada;

		::v-deep .result-marker {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: 600;
			width: 36px;
			height: 36px;

			transform: translateY(-50%) translateX(-50%);

			border-radius: 50%;

			color: #fff;

			&.good {
				background-color: #089108;
			}

			&.medium {
				background-color: #bd860b;
			}

			&.bad {
				background-color: #9f0d25;
			}

			&.no-rank {
				background-color: #595959;
			}
		}

		> div.profiles {
			min-width: 350px;
			width: 350px;
			background-color: #fff;

			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			> div.top {
				position: sticky;
				top: 0;

				z-index: 2;

				margin: 0;
				padding: 25px 15px;

				background-color: #fff;

				border-bottom: 1px solid #eee;

				display: flex;
				flex-direction: column;
				gap: 15px;

				> h2 {
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					padding: 0;
				}

				> div.search {
					position: relative;
					border: 1px solid #ccc;
					border-radius: 6px;

					> i {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						color: #000;
						font-size: 14px;
						padding: 0 12px;
						z-index: 2;

						pointer-events: none;

						display: flex;
						align-items: center;
					}

					> input {
						width: 100%;
						height: 40px;

						border: 0;
						outline: 0;
						box-shadow: none;
						background-color: transparent;
						padding: 0 36px;
					}

					> button {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						color: #000;
						font-size: 14px;
						padding: 0 12px;
						z-index: 2;

						display: flex;
						align-items: center;

						border: 0;
						outline: 0;
						box-shadow: none;
						background-color: transparent;
					}
				}
			}

			> div.loader {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 6px;
				color: #6b6b6b;
				font-size: 13px;
				text-align: center;
				padding: 25px;
			}

			> div.placeholder {
				color: #6b6b6b;
				font-size: 13px;
				text-align: center;
				padding: 25px;
			}

			> div.results {
				display: flex;
				flex-direction: column;

				> div.result {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 10px;

					padding: 15px;

					border-top: 1px solid #eee;

					> div.meta {
						display: flex;
						flex-direction: column;
						gap: 2px;

						flex: 1;
						min-width: 0;

						> div.name {
							color: #000;
							font-size: 13px;
							font-weight: 500;
						}

						> div.address {
							color: #363636;
							font-size: 12px;
							font-weight: 400;
							white-space: nowrap;
							min-width: 0;
							overflow: hidden;
							width: 100%;
						}

						> div.rating {
							display: flex;
							align-items: center;

							> span.rating {
								color: #464646;
								font-size: 12px;
								font-weight: 400;
								margin-right: 5px;
								padding-top: 2px;
							}

							> span.count {
								color: #464646;
								font-size: 12px;
								font-weight: 400;
								margin-left: 5px;
								padding-top: 2px;
							}

							> .star {
								color: #ccc;
								font-size: 12px;

								--fa-primary-color: #dea20c;
								--fa-secondary-color: #939292;

								&.full {
									color: #dea20c;
								}
							}
						}
					}

					> div.position  {
						position: relative;

						> div.value {
							color: #fff;
							font-size: 12px;
							letter-spacing: -.2px;
							font-weight: 500;

							padding: 2px 6px;
							border-radius: 3px;

							&.good {
								background-color: #089108;
							}

							&.medium {
								background-color: #bd860b;
							}

							&.bad {
								background-color: #9f0d25;
							}
						}

						&:hover {
							> div.tip {
								opacity: 1;
							}
						}

						> div.tip {
							pointer-events: none;
							opacity: 0;

							transition: all .25s ease-in-out;

							position: absolute;
							top: 0;
							right: 0;

							color: #fff;
							font-size: 12px;
							width: 300px;
							white-space: normal;
							padding: 8px;
							border-radius: 8px;
							background-color: rgba(0, 0, 0, .85);

							transform: translateY(-100%) translateY(-10px);

							z-index: 99;

							&:after {
								position: absolute;

								border-left: 4px solid transparent;
								border-right: 4px solid transparent;

								border-top: 3px solid rgba(0, 0, 0, .85);

								bottom: 0;
								right: 10px;

								transform: translateY(100%);

								content: '';
							}
						}
					}

					&.active {
						background-color: #eee;
					}

					&:first-child {
						border-top: 0;
					}

					&:hover {
						cursor: pointer;
						user-select: none;
						background-color: #eee;
					}
				}
			}
		}

		> div.map-wrapper {
			flex: 1;

			position: relative;
			background-color: #eee;

			> div.serp-map {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				width: 100%;
				height: 100%;

				z-index: 1;
			}

			> div.search {
				position: absolute;
				top: 15px;
				left: 15px;

				display: flex;
				align-items: center;

				border-radius: 6px;
				overflow: hidden;
				background-color: #fff;

				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

				z-index: 3;

				> div.field {
					position: relative;

					display: flex;
					align-items: center;

					> input {
						width: 250px;
						font-size: 13px;
						padding: 0 35px 0 15px;
						border: 0;
						height: 40px;
						background-color: #fff;

						transition: all .25s ease-in-out;

						&:disabled {
							cursor: not-allowed;
							opacity: .5;
						}
					}

					> i {
						position: absolute;

						top: 0;
						right: 0;

						width: 40px;
						height: 40px;

						display: flex;
						align-items: center;
						justify-content: center;

						z-index: 2;
					}

					&:after:not(.last) {
						content: '';
						width: 1px;
						height: 20px;
						background-color: #ccc;
					}

					.switch {
						display: flex;
						align-items: center;
						gap: 8px;
						margin-right: 10px;

						> button {
							display: flex;
							align-items: center;
							gap: 6px;
							border: 0;
							color: #818181 !important;
							font-weight: 500 !important;
							outline: 0 !important;
							background-color: transparent !important;

							transition: all .25s ease-in-out;

							&:hover {
								color: #000 !important;
							}

							&.active {
								color: #000 !important;

								span {
									text-decoration: underline;
								}
							}
						}
					}
				}

				> button {
					display: flex;
					align-items: center;
					gap: 6px;

					height: 40px;
					color: #fff !important;
					font-size: 13px;
					font-weight: 600;
					padding: 0 20px;
					letter-spacing: -.1px !important;
					border: 0 !important;
					background-color: #000 !important;
					border-radius: 6px;

					transition: all .25s ease-in-out;

					&:disabled {
						cursor: not-allowed;
						opacity: 0.5;
					}
				}
			}

			> div.loader {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				left: 0;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				gap: 20px;

				background-color: rgba(0, 0, 0, .1);

				> div.spinner {
					width: 90px;
					height: 90px;

					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 15px;

					border-radius: 8px;

					background-color: #fff;

					i {
						color: #000;
						font-size: 26px;
					}

					span {
						color: #000;
						font-size: 12px;
						font-weight: 500;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
</style>

<script>
import pLimit from 'p-limit'

import SerpGridService from '@/services/serp-grid/SerpGridService'
import SerpTips from "@/app/serp-grid/SerpTips";

export default {
	components: {SerpTips},
	data: () => ({
		id: null,

		type: 'map',
		query: '',

		keyword: '',
		address: '',

		grid: {},
		markers: [],

		results: {},

		selectedKeyword: null,
		autocomplete: null,
		bounds: null,
		map: null,

		active: false,
		loading: false,

		gridSize: 350,

		offset: 0
	}),

	mounted() {
		this.initialize()
	},

	computed: {
		progress() {
			return {
				pending: Object.keys(this.results).length - this.offset,
				total: Object.keys(this.grid).length - this.offset
			}
		},

		canSubmit() {
			return !! this.bounds && String(this.keyword || '').length > 0
		},

		sortedProfiles() {
			return Object.values(this.profiles).sort((a, b) => {
				return a.average_rank > b.average_rank
					? 1
					: -1
			})
		},

		profiles() {
			const query = String(this.query || '').toLocaleLowerCase()
			const hasQuery = query.length > 0

			return JSON.parse(JSON.stringify(
				Object.values(this.results)
					.reduce(
					(carry, profiles) => profiles.reduce(
							(c, i) => {
								if (hasQuery &&
									! i.title.toLocaleLowerCase().includes(query) &&
									! String(i.address || '').toLocaleLowerCase().includes(query)
								) {
									return c
								}

								return {
									...c,
									[i.id]: {
										...i,
										rankings: ((c[i.id] || {}).rankings || 0) + 1,
										average_rank: (((c[i.id] || {}).total_rank || 0) + i.rank) / (((c[i.id] || {}).rankings || 0) + 1),
										total_rank: ((c[i.id] || {}).total_rank || 0) + i.rank
									}
								}
							},
							carry
						),
						{}
					)
			))
		}
	},

	watch: {
		address() {
			this.reset()
		},

		keyword() {
			this.reset()
		},

		type() {
			this.reset()
		},

		results() {
			this.draw()
		},

		id() {
			this.draw()
		},

		query() {
			this.setDefault()
		}
	},

	methods: {
		stars(rating) {
			return {
				full: Math.floor(rating),
				half: rating % 1 !== 0,
				empty: 5 - Math.ceil(rating)
			}
		},

		async initialize() {
			if (! window.google) {
				setTimeout(this.initialize, 500)
				return
			}

			// Autocomplete

			this.autocomplete = new google.maps.places.SearchBox(this.$refs.address)

			this.autocomplete.addListener('places_changed', () => {
				const place = this.autocomplete.getPlaces()[0] || null

				if (! place) {
					return
				}

				// Set address

				this.address = place.formatted_address

				// Set location

				this.map.setCenter(place.geometry.location)
				this.map.setZoom(16)
			})

			// Initialize map

			const { Map } = await google.maps.importLibrary("maps");

			this.map = new Map(this.$refs.map, {
				center: { lat: 55.682380, lng: 12.513960 },
				zoom: 16,
				minZoom: 15,
				disableDefaultUI: true,
			})

			const setBounds = (bounds) => {
				this.bounds = bounds
				this.updateLocations()
			}

			google.maps.event.addListener(this.map, 'idle', function(){
				setBounds(this.getBounds())
			})
		},

		async updateLocations() {
			if (! this.active || ! this.bounds) {
				return
			}

			const bounds = this.bounds

			const ne = bounds.getNorthEast()
			const sw = bounds.getSouthWest()

			const currentKeys = Object.keys(this.grid)

			const grid = this.createGrid(ne, sw).filter(point => ! currentKeys.includes(`${point.lat}-${point.lng}`))

			// Resolve elevation

			let elevatedGrid = []

			if (grid.length > 0) {
				const elevator = new google.maps.ElevationService()

				const elevation = await elevator
					.getElevationForLocations({
						locations: grid,
					})

				let i = 0

				elevatedGrid = elevation.results.reduce(
					(carry, item) => {
						const point = grid[i++]

						return item.elevation > 1
							? { ...carry, [`${point.lat}-${point.lng}`]: { lat: point.lat, lng: point.lng } }
							: carry
					},
					{}
				)
			}

			// Set grid

			this.offset = Object.keys(this.grid).length

			this.grid = {
				...this.grid,
				...elevatedGrid
			}

			// Fetch results

			this.loading = true

			const limit = pLimit(5);

			const input = Object.values(elevatedGrid).map(point => {
				return limit(() => {
					return new Promise((resolve) => {
						SerpGridService.fetch({
							keyword: this.selectedKeyword,
							lat: point.lat,
							lng: point.lng,
							type: this.type
						}, (response) => {
							this.$set(this.results, `${point.lat}-${point.lng}`, response.data)

							this.setDefault()

							resolve()
						}, () => {
							resolve()
						})
					})
				})
			})

			await Promise.all(input).then(() => {
				this.loading = false
			})
		},

		createGrid(northEast, southWest) {
			const gridSize = this.gridSize

			const latitudes = [];
			const longitudes = [];

			const latitudeDegreeSize = 111320
			const longitudeDegreeSize = 81320

			const gridSizeLatitudeDegrees = gridSize / latitudeDegreeSize
			const gridSizeLongitudeDegrees = gridSize / longitudeDegreeSize

			let currentLat = this.snapToGrid(southWest.lat(), gridSizeLatitudeDegrees)
			let currentLng = this.snapToGrid(southWest.lng(), gridSizeLongitudeDegrees)

			while (currentLat <= northEast.lat()) {
				latitudes.push(currentLat)
				currentLat += gridSizeLatitudeDegrees
			}

			while (currentLng <= northEast.lng()) {
				longitudes.push(currentLng)
				currentLng += gridSizeLongitudeDegrees
			}

			const grid = [];
			for (let lat of latitudes) {
				for (let lng of longitudes) {
					grid.push({ lat, lng })
				}
			}

			return grid
		},

		snapToGrid(coordinate, gridSizeInDegrees) {
			return Math.round(coordinate / gridSizeInDegrees) * gridSizeInDegrees;
		},

		draw() {
			this.removeMarkers()

			if (! this.id) {
				return
			}

			const ResultMarker = require('./ResultMarker').default

			// Draw markers

			Object.values(this.grid).forEach(point => {
				const match = (this.results[`${point.lat}-${point.lng}`] || [])
					.find(item => item.id === this.id)

				if (! match && this.loading) {
					return
				}

				const bounds = {
					lat: point.lat,
					lng: point.lng
				}

				const marker = new ResultMarker(bounds, !! match ? match.rank : '-')
				marker.setMap(this.map)

				this.markers.push(marker)
			})
		},

		onSearch() {
			this.removeMarkers()

			// Reset
			this.reset()

			// Set values

			this.active = true
			this.selectedKeyword = this.keyword

			// Update locations

			this.updateLocations()
		},

		reload() {
			this.locations = []
		},

		reset() {
			this.active = false
			this.grid = {}
			this.results = {}
			this.query = ''

			this.removeMarkers()
		},

		setType(type) {
			if (this.loading) {
				return
			}

			this.type = type
		},

		removeMarkers() {
			for(let i = 0; i < this.markers.length; i++) {
				this.markers[i].setMap(null)
			}

			this.markers = []
		},

		setDefault() {
			const profiles = this.sortedProfiles
			const current = profiles.find(item => item.id === this.id)

			if (! profiles.length || current) {
				return
			}

			this.id = profiles[0].id
		}
	}
}
</script>
